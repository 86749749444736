<template lang="pug">
    .a-client-details.container.flex
        .mx-auto.w-full.max-w-960.mt-8.mb-8.p-4.bg-white-pure.l-box-shadow.rounded
            h2.text-center.my-4
                | Install the Street Uni App
            div.text-center Access the Street Uni app to manage your Street Uni account, bookings and more!
            div.text-center.mb-4 Available on iOS and Android.
            .flex.flex-row.pt-4
                a( :href="iosAppLink" class="text-center flex w-full justify-end mb-4" )
                    img( src="@/assets/images/button-appstore.png" class="w-150 mr-4")
                a( :href="androidAppLink" class="text-center flex w-full justify-start mb-4" )
                    img( src="@/assets/images/button-playstore.png" class="w-150")

</template>

<script>
import { ref } from '@vue/composition-api'

export default {
    name: 'TsuApp',
    setup() {
        const appLink = ref('stuniapp://open')
        const androidAppLink = ref('https://play.google.com/store/apps/details?id=com.sparecreative.tsu')
        const iosAppLink = ref('https://apps.apple.com/au/app/street-university/id1547340998')

        return {
            appLink,
            androidAppLink,
            iosAppLink
        }
    }
}
</script>
